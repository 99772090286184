<template>
  <v-card tile class="">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text class="text-center">
      <v-container class="pa-12">
        <v-row dense class="d-flex flex-row justify-center align-center">
          <v-col cols="12">
            <h2>{{ addBalance ? "Agregar" : "Restar" }} balance</h2>
          </v-col>
          <v-col cols="12">
            <p class="">
              ¿Desea {{ addBalance ? "agregar" : "restar" }} balance al
              siguiente panel corporativo?
            </p>
            <p>
              <span class="font-weight-bold">Balance master:</span>
              <span class="ml-2">{{ wallet | currency }}</span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :disable-sort="!false"
              :items="item"
              class="dataTable"
              :hide-default-footer="true"
            >
              <template #item.wallet="{ item }">
                <span> {{ item.wallet | currency }}</span>
              </template>
              <template v-slot:item.is_approved="{ item }">
                <v-chip
                  v-if="
                    item.is_approved === 'Aprobado' ||
                    item.is_approved === 'Approved'
                  "
                  class="v-chip-light-bg success--text"
                  color="accent"
                >
                  {{ item.is_approved }}
                </v-chip>
                <v-chip
                  v-if="
                    item.is_approved === 'No aprobado' ||
                    item.is_approved === 'No aprobado'
                  "
                  class="v-chip-light-bg error--text"
                  color="accent"
                >
                  {{ item.is_approved }}
                </v-chip>
              </template>
              <template v-slot:item.overdraft="{ value }">
                <v-chip
                  v-if="value === true"
                  class="v-chip-light-bg success--text"
                  color="accent"
                >
                  Activo
                </v-chip>
                <v-chip
                  v-if="value === false"
                  class="v-chip-light-bg error--text"
                  color="accent"
                >
                  Inactivo
                </v-chip>
                <span v-if="value === '--'"> -- </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-chip
              class="v-chip-light-bg"
              :class="[
                addBalance && 'border-chip-add',
                wallet >= 0 && 'success--text',
              ]"
              color="accent"
              @click="addBalance = true"
              :disabled="wallet < 0"
            >
              Agregar
            </v-chip>
            <v-chip
              class="v-chip-light-bg ml-6 error--text"
              :class="!addBalance && 'border-chip-cut'"
              color="accent"
              @click="addBalance = false"
            >
              Restar
            </v-chip>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-row justify-center">
          <v-col cols="12" md="6" class="d-flex flex-column">
            <!-- <code>{{ price }}</code> -->
            <!-- <code>{{price.length >= 8 && price.replace(/,/g,'')}}</code> -->
            <!-- <code>{{ computedDisabled }}</code> -->
            <v-text-field
              :label="addBalance ? 'Agregar balance' : 'Restar balance'"
              outlined
              dense
              v-model.lazy="price"
              v-money="money"
              prefix="$"
              :error-messages="
                parseFloat(price) !== 0 &&
                computedDisabled &&
                'Nota: No se puede agregar por encima del balance corporativo master ni restar por encima del balance de un panel corporativo asociado'
              "
              :error="parseFloat(price) !== 0 && computedDisabled"
              @keydown="nameKeydown($event)"
            />
            <span
              v-if="parseFloat(price) > 0 && !computedDisabled"
              class="d-flex flex-column"
            >
              <span>
                <span class="font-weight-bold">Balance corporativo master:</span>
                <span class="ml-2" v-if="addBalance">{{
                  (wallet -
                    parseFloat(
                      price.length >= 8
                        ? parseFloat(price.replace(/,/g, ""))
                        : parseFloat(price)
                    ))
                    | currency
                }}</span>
                <span class="ml-2" v-if="!addBalance">{{
                  (wallet +
                    parseFloat(
                      price.length >= 8
                        ? parseFloat(price.replace(/,/g, ""))
                        : parseFloat(price)
                    ))
                    | currency
                }}</span>
              </span>
              <span>
                <span class="font-weight-bold">Balance panel corporativo afiliado:</span>
                <span class="ml-2" v-if="addBalance">{{
                  (item[0].wallet +
                    parseFloat(
                      price.length >= 8
                        ? parseFloat(price.replace(/,/g, ""))
                        : parseFloat(price)
                    ))
                    | currency
                }}</span>
                <span class="ml-2" v-if="!addBalance">{{
                  (item[0].wallet -
                    parseFloat(
                      price.length >= 8
                        ? parseFloat(price.replace(/,/g, ""))
                        : parseFloat(price)
                    ))
                    | currency
                }}</span>
              </span>
            </span>
          </v-col>
        </v-row>
        <v-row
          class="
            d-flex
            flex-md-row flex-column-reverse
            justify-center
            align-center
          "
        >
          <v-col cols="12" md="4">
            <v-btn color="primary" block outlined @click="handleClose()">
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              color="primary"
              block
              @click="handleBalance()"
              :disabled="computedDisabled"
            >
              Aceptar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiWhatsapp } from "@mdi/js";
import { mdiMagnify, mdiAlert } from "@mdi/js";
import { VMoney } from "v-money";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";

export default {
  directives: { money: VMoney },
  props: {
    item: {
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        mdiWhatsapp,
        mdiMagnify,
        mdiAlert,
      },
      alertError: false,
      price: 0,
      money: {
        decimal: ".",
        thousands: ",",
        precision: 2,
      },
      headers: [
        {
          text: "PANEL ID",
          value: "unique_id",
          align: "start",
          sortable: false,
        },
        {
          text: "NAME",
          value: "name",
          align: "start",
          sortable: !false,
        },
        {
          text: "PHONE",
          value: "phone",
          align: "start",
          sortable: !false,
        },
        { text: "EMAIL", value: "email", align: "start", sortable: !false },
        { text: "BALANCE ", value: "wallet", align: "end" },
        { text: "STATUS", value: "is_approved", align: "center" },
      ],
      addBalance: true,
      customRules: [
        (v) =>
          (v && v <= this.wallet) || "El monto no puede ser mayor al wallet",
      ],
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("auth", ["me"]),

    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },

    async handleSearch() {
      this.alertError = true;
      await new Promise((r) => setTimeout(r, 5000));
      this.alertError = false;
    },
    handleClose() {
      this.$emit("submit", null);
    },
    async handleBalance() {
      this.setLoading(true);
      try {
        const { data } = await axios.post("/corporate_add_limit_from_master", {
          wallet: this.addBalance
            ? this.price.length >= 8
              ? parseFloat(this.price.replace(/,/g, ""))
              : parseFloat(this.price)
            : (this.price.length >= 8
                ? parseFloat(this.price.replace(/,/g, ""))
                : parseFloat(this.price)) * -1,
          corporate_id: this.item[0]._id,
        });
        if (data.success) {
          this.$dialog.notify.success(
            this.addBalance
              ? `$${this.price} han sido agregados al panel corporativo ${this.item[0].name}`
              : `$${this.price} han sido restados al panel corporativo ${this.item[0].name}`
          );
          this.$emit("submit", true);
        } else {
          throw data.error_code;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    tableCellBalance(value) {
      if (value > 0) {
        return `color-green-text`;
      }
      if (value < 0) {
        return `color-red-text`;
      }
      return ``;
    },
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft"]),
    computedDisabled() {
      if (this.addBalance) {
        if (
          (this.price.length >= 8
            ? parseFloat(this.price.replace(/,/g, ""))
            : parseFloat(this.price)) === 0 ||
          (this.price.length >= 8
            ? parseFloat(this.price.replace(/,/g, ""))
            : parseFloat(this.price)) > parseFloat(this.wallet)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          (this.price.length >= 8
            ? parseFloat(this.price.replace(/,/g, ""))
            : parseFloat(this.price)) > parseFloat(this.item[0].wallet) ||
          (this.price.length >= 8
            ? parseFloat(this.price.replace(/,/g, ""))
            : parseFloat(this.price)) === 0
        ) {
          return true;
        } else {
          return false;
        }
      }
      // return this.item[0].balance
    },
  },
  created() {
    this.wallet >= 0 ? (this.addBalance = true) : (this.addBalance = false);
  },
};
</script>
<style lang="sass" scoped>
.vuedl-layout__closeBtn
  color: rgba(58, 53, 65, 0.54)
    // color: white
    // opacity: 1
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    height: 200vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px

.border-chip-cut
  border: solid #EF4370 !important

.border-chip-add
  border: solid #41D6B0 !important
</style>
