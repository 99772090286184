<template>
  <div class="d-flex flex-column justify-content-between">
    <!-- Titulo -->
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-5">
          <v-row dense>
            <v-col
              cols="12"
              class="d-flex flex-row justify-md-start justify-center"
            >
              <span class="d-flex flex-row justify-start align-center">
                <h2>{{ $t("Corporate Panels") }}</h2>
                <v-tooltip top :max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-6 color-gray-text"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("tooltipCorporatePanels1") }}
                    <br />
                    {{ $t("tooltipCorporatePanels2") }}
                  </span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>
          <!-- Filtros -->
          <v-row dense class="mt-4 d-flex flex-row justify-space-between">
            <v-col cols="12" md="8">
              <v-row dense>
                <v-col md="4" cols="12">
                  <v-text-field
                    :label="$t('Search')"
                    :prepend-inner-icon="icons.mdiMagnify"
                    outlined
                    dense
                    v-model="search"
                    clearable
                    @keyup.esc="search = ''"
                    :disabled="table.tableLoading"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="d-flex flex-row justify-end align-start"
            >
              <vue-excel-xlsx
                v-if="!new_"
                :data="computedFilteredCorporatesExcel"
                :columns="table.headersExport"
                :file-name="`${$t('Corporate Panels')}` + '-' + `${today}`"
                :file-type="'xlsx'"
                :sheet-name="$t('Corporate Panels')"
                :style="'width: 100%;'"
              >
                <v-btn
                  block
                  outlined
                  color="primary"
                  :disabled="table.tableLoading"
                >
                  <v-icon> {{ icons.mdiDownload }}</v-icon>
                  <span class="ml-2">{{ $t("Export") }}</span>
                </v-btn>
              </vue-excel-xlsx>
            </v-col>
          </v-row>
          <!-- tabla -->
          <v-row dense class="mt-4">
            <v-col cols="12">
              <DataTable
                :loading="table.tableLoading"
                :headers="table.headers"
                :items="computedFilteredCorporates"
                :search="search"
                v-if="!new_"
                @refresh="handleRefresh()"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Placeholder de nuevos -->
    <v-row v-if="new_" class="custom-margin">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <v-img
          src="../Users/assets/typography_es.png"
          width="300"
          v-if="!$vuetify.theme.isDark"
        ></v-img>
        <v-img
          src="../Users/assets/typography_es_dark.png"
          width="300"
          v-else
        ></v-img>
      </v-col>
    </v-row>

    <!-- Agregar usuarios -->
    <v-row v-if="false">
      <v-col
        cols="12"
        class="d-flex flex-row justify-md-end justify-center align-center"
      >
        <v-btn color="success" rounded @click="toggleModal()">
          <v-icon style="color: #ffffff !important">{{ icons.mdiPlus }}</v-icon>
          <span class="ml-2">{{ $t("Add to payroll") }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="1">
        <v-switch v-model="new_" label="nuevo" />
      </v-col>
    </v-row> -->
    <!-- <code>
      {{ table.users }}
    </code> -->
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiDownload,
  mdiPlus,
} from "@mdi/js";
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";
import DataTable from "./parts/DataTable.vue";
import Modal from "./parts/Modal.vue";
import ModalEN from "./parts/ModalEN.vue";

export default {
  components: {
    DataTable,
    Modal,
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMagnify,
        mdiCalendar,
        mdiDotsVertical,
        mdiDownload,
        mdiPlus,
      },
      selectionFilter: [this.$t("Active")],
      new_: false,
      table: {
        users: [],
        usersExcel: [],
        tableLoading: false,
        headers: [
          {
            text: "PANEL ID",
            value: "unique_id",
            align: "start",
            sortable: false,
          },
          {
            text: "NAME",
            value: "name",
            align: "start",
            sortable: !false,
            width: 220
          },
          {
            text: "PHONE",
            value: "phone",
            align: "start",
            sortable: !false,
          },
          { text: "EMAIL", value: "email", align: "start", sortable: !false },
          { text: "BALANCE ", value: "wallet", align: "end" },
          { text: "USUARIOS EN NÓMINA", value: "users_count", align: "end" },
          { text: "BALANCE EN NÓMINA", value: "wallet_payroll", align: "end" },
          { text: "STATUS", value: "is_approved", align: "center" },
          {
            text: "ACCIÓN",
            value: "actions",
            sortable: false,
            align: "center",
          },
        ],
        headersExport: [
          { label: this.$t("USER ID"), field: "unique_id" },
          { label: this.$t("NAME"), field: "name" },
          { label: this.$t("PHONE"), field: "phone" },
          { label: this.$t("EMAIL"), field: "email" },
          { label: this.$t("BALANCE"), field: "wallet" },
          { label: this.$t("STATUS"), field: "is_approved" },
        ],
      },
      search: "",
      today: "",
    };
  },
  methods: {
    ...mapActions("auth", ["meNotLoading"]),
    async toggleModal() {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "50%",
        transition: "dialog-top-transition",
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(Modal, params);
        if (!dialogInstance) {
          dialogInstance.close;
        } else {
          dialogInstance.close;
          this.getCorporates();
          this.selectionFilter = [this.$t("Waiting for approval")];
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(ModalEN, params);
        if (!dialogInstance) {
          dialogInstance.close;
        } else {
          dialogInstance.close;
          this.getCorporates();
          this.selectionFilter = [this.$t("Waiting for approval")];
        }
      }
    },
    async getCorporates() {
      this.table.tableLoading = true;
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/get_corporates`
        );
        if (!!data.success) {
          this.new_ = false;
          data.corporates.forEach((corp) => {
            corp["is_approved"] = Boolean(corp.is_approved)
              ? this.$t("Approved")
              : this.$t("Not approved");
            corp["phone"] = corp.country_phone_code + corp.phone;
          });
          this.table.users = data.corporates;
          this.table.usersExcel = data.corporates;
        } else {
          this.table.users = [];
          this.new_ = true;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.meNotLoading();
      this.table.tableLoading = false;
    },
    handleRefresh() {
      this.getCorporates();
    },
  },
  created() {
    this.getCorporates();
    let today = new Date();
    this.today = this.$moment(today).add(-4, "hours").toISOString();
  },
  computed: {
    computedFilteredCorporates() {
      let filtered = this.table.users;
      return filtered;
    },
    computedFilteredCorporatesExcel() {
      let filtered = this.table.usersExcel;
      return filtered;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-margin {
  margin-top: 75px !important;
}
.icon-white {
  color: #ffffff !important;
}
.cursor-hover:hover {
  cursor: pointer;
}
</style>
