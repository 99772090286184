<template>
  <span>
    <v-data-table
      :loading="loading"
      :headers="computedHeaders"
      :disable-sort="!true"
      :items="items"
      class="dataTable elevation-1"
      v-model="selected"
      item-key="unique_id"
      :search="search"
      mobile-breakpoint="0"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
      }"
    >
      <!-- show-select -->
      <template v-slot:top>
        <v-row dense class="mb-1">
          <v-col
            cols="12"
            class="d-flex flex-md-row flex-column justify-end align-center"
          >
            <!-- placeholder para poder dar espacio -->
            <span :class="!$vuetify.theme.isDark && 'color-blue-text'">
              {{ $t("Corporate Panels Totals") }}:
              <span class="font-weight-bold">{{ items && items.length }}</span>
            </span>
            <span
              class="ml-2"
              :class="!$vuetify.theme.isDark && 'color-blue-text'"
            >
              <span class="d-md-inline d-none">|</span> Total balance
              {{ $t("in corporate panels") }}:
              <span class="font-weight-bold">
                {{
                  items.reduce((accumulator, item) => {
                    return accumulator + item.wallet;
                  }, 0) | currency
                }}
              </span>
            </span>

            <span class="ml-2">
              <v-tooltip top :max-width="300">
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="primary"
                    @click="handleRefresh()"
                  >
                    <v-icon>
                      {{ icons.mdiRefresh }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("Refresh table") }}
                </span>
              </v-tooltip>
            </span>
          </v-col>
        </v-row>
      </template>
      <!-- <template #item="{ item }">
        <tr>
          <td v-for="(cell, i) in item" :key="i">
            <span v-if="i !== 10">
              {{ cell }}
            </span>
            <span v-else>

            </span>
          </td>
        </tr>
      </template> -->
      <template #item.name="{ item }">
        <span>
          <a
            target="_blank"
            :href="`/corporate_panel/${item._id}`"
            style="text-decoration: none"
          >
            <v-avatar color="success" size="30">
              <span class="d-flex align-center justify-center">
                <v-img
                  :src="`${bucketUrl}${item.image_url}`"
                  :alt="item.name"
                  width="30"
                  v-if="item.image_url"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
                <span v-else class="color-blue-text">
                  {{ item.name[0] }}
                </span>
              </span>
            </v-avatar>
            <span class="ml-1">
              {{ item.name }}
            </span>
          </a>
        </span>
      </template>
      <template #item.wallet="{ item }">
        <span :class="tableCellBalance(item.wallet)">
          {{ item.wallet | currency }}</span
        >
      </template>
      <template #item.wallet_payroll="{ item }">
        <span :class="tableCellBalance(item.wallet_payroll)">
          {{ item.wallet_payroll | currency }}</span
        >
      </template>
      <template v-slot:item.is_approved="{ item }">
        <v-chip
          v-if="item.is_approved === $t('Approved')"
          class="v-chip-light-bg success--text"
          color="accent"
        >
          {{ item.is_approved }}
        </v-chip>
        <v-chip
          v-if="item.is_approved === $t('Not approved')"
          class="v-chip-light-bg error--text"
          color="accent"
        >
          {{ item.is_approved }}
        </v-chip>
      </template>
      <template v-slot:item.overdraft="{ value }">
        <v-chip
          v-if="value === true"
          class="v-chip-light-bg success--text"
          color="accent"
        >
          {{ $t("Active") }}
        </v-chip>
        <v-chip
          v-if="value === false"
          class="v-chip-light-bg error--text"
          color="accent"
        >
          {{ $t("Inactive") }}
        </v-chip>
        <span v-if="value === '--'"> -- </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu rounded=".rounded-lg" offset-y :disabled="loading">
          <template v-slot:activator="{ attrs, on }">
            <!-- class="white--text ma-5" -->
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="handleShowUser([item])">
              <v-list-item-title>{{
                $t("View corporate panel")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="toggleBalanceModal([item])">
              <v-list-item-title>{{ $t("Balance") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <!-- <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize"> Reset </v-btn>
                </template> -->
    </v-data-table>
  </span>
</template>
<script>
import DeleteModal from "./DeleteModal.vue";
import DeleteModalEN from "./DeleteModalEN.vue";
import OverdraftModal from "./OverdraftModal.vue";
import OverdraftModalEN from "./OverdraftModalEN.vue";
import BalanceModal from "./BalanceModalCorporate.vue";
import AddModal from "./AddModal.vue";
import AddModalEN from "./AddModalEN.vue";

import { mapActions, mapState } from "vuex";

import {
  mdiDotsVertical,
  mdiCurrencyUsd,
  mdiDeleteOutline,
  mdiRefresh,
  mdiInformationOutline,
} from "@mdi/js";
export default {
  components: { DeleteModal, OverdraftModal, BalanceModal, AddModal },
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
    search: {
      type: String,
    },
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
        mdiDeleteOutline,
        mdiRefresh,
        mdiInformationOutline,
      },
      selected: [],
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
    };
  },
  methods: {
    handleRefresh() {
      this.$emit("refresh");
    },
    async toggleDeleteModal(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "60%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: item,
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          DeleteModal,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          DeleteModalEN,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      }
    },
    async toggleOverdraftModal(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "60%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: item,
      };

      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(
          OverdraftModal,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          OverdraftModalEN,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      }
    },
    async toggleBalanceModal(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "60%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: item,
      };
      const dialogInstance = await this.$dialog.showAndWait(
        BalanceModal,
        params
      );
      if (dialogInstance) {
        this.$emit("refresh");
      } else {
      }
    },
    async toggleAddModal(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: "60%",
        transition: "dialog-top-transition",
        actions: {
          false: "No",
          true: "Yes",
        },
        item: item,
      };
      if (this.$vuetify.lang.current === "es") {
        const dialogInstance = await this.$dialog.showAndWait(AddModal, params);
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      } else {
        const dialogInstance = await this.$dialog.showAndWait(
          AddModalEN,
          params
        );
        if (!dialogInstance) {
          dialogInstance.close();
        } else {
          this.$emit("refresh");
          dialogInstance.close();
        }
      }
    },
    handleShowUser(item) {
      let route = this.$router.resolve({
        name: "corporatePanel",
        params: { id: item[0]._id },
      });
      window.open(route.href, "_blank").focus();
    },
    tableCellBalance(value) {
      if (value >= 0.01) {
        return `color-green-text`;
      }
      if (value < 0) {
        return `color-red-text`;
      }
      return;
    },
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft"]),
    computedHeaders() {
      let headers = this.headers;
      headers.map((head) => (head.text = this.$t(head.text)));
      return headers;
    },
  },
};
</script>
<style lang="scss">
.dataTable {
  tbody tr:nth-of-type(even) {
    background-color: rgba(58, 53, 65, 0.04);
  }

  tbody tr:hover {
    background-color: rgba(0, 5, 34, 0.08) !important;
  }

  tbody tr {
    border-bottom: hidden !important;
  }
}
.custom-margin {
  margin-bottom: 14px;
}
</style>
