var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.computedHeaders,"disable-sort":!true,"items":_vm.items,"item-key":"unique_id","search":_vm.search,"mobile-breakpoint":"0","footer-props":{
      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mb-1",attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-md-row flex-column justify-end align-center",attrs:{"cols":"12"}},[_c('span',{class:!_vm.$vuetify.theme.isDark && 'color-blue-text'},[_vm._v(" "+_vm._s(_vm.$t("Corporate Panels Totals"))+": "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.items && _vm.items.length))])]),_c('span',{staticClass:"ml-2",class:!_vm.$vuetify.theme.isDark && 'color-blue-text'},[_c('span',{staticClass:"d-md-inline d-none"},[_vm._v("|")]),_vm._v(" Total balance "+_vm._s(_vm.$t("in corporate panels"))+": "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.items.reduce(function (accumulator, item) { return accumulator + item.wallet; }, 0)))+" ")])]),_c('span',{staticClass:"ml-2"},[_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.handleRefresh()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("Refresh table"))+" ")])])],1)])],1)]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"target":"_blank","href":("/corporate_panel/" + (item._id))}},[_c('v-avatar',{attrs:{"color":"success","size":"30"}},[_c('span',{staticClass:"d-flex align-center justify-center"},[(item.image_url)?_c('v-img',{attrs:{"src":("" + _vm.bucketUrl + (item.image_url)),"alt":item.name,"width":"30"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_c('span',{staticClass:"color-blue-text"},[_vm._v(" "+_vm._s(item.name[0])+" ")])],1)]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])]}},{key:"item.wallet",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:_vm.tableCellBalance(item.wallet)},[_vm._v(" "+_vm._s(_vm._f("currency")(item.wallet)))])]}},{key:"item.wallet_payroll",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:_vm.tableCellBalance(item.wallet_payroll)},[_vm._v(" "+_vm._s(_vm._f("currency")(item.wallet_payroll)))])]}},{key:"item.is_approved",fn:function(ref){
    var item = ref.item;
return [(item.is_approved === _vm.$t('Approved'))?_c('v-chip',{staticClass:"v-chip-light-bg success--text",attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(item.is_approved)+" ")]):_vm._e(),(item.is_approved === _vm.$t('Not approved'))?_c('v-chip',{staticClass:"v-chip-light-bg error--text",attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(item.is_approved)+" ")]):_vm._e()]}},{key:"item.overdraft",fn:function(ref){
    var value = ref.value;
return [(value === true)?_c('v-chip',{staticClass:"v-chip-light-bg success--text",attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(_vm.$t("Active"))+" ")]):_vm._e(),(value === false)?_c('v-chip',{staticClass:"v-chip-light-bg error--text",attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(_vm.$t("Inactive"))+" ")]):_vm._e(),(value === '--')?_c('span',[_vm._v(" -- ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"rounded":".rounded-lg","offset-y":"","disabled":_vm.loading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleShowUser([item])}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("View corporate panel")))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleBalanceModal([item])}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Balance")))])],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }